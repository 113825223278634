import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <p>
         Welcome to 
        </p>
        <img alt="Shallows Logo" className="App-logo" src={logo}/>
        <p class="sub-heading">Mobile App for Ski Lakes</p>
      </header>
    </div>
  );
}

export default App;
